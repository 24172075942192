<template>
  <div class="w-full">
    <div>
      <h2 class="text-base tracking-wide font-semibold text-center mb-5">Continue with your email</h2>
      <input
        v-model="email"
        class="block relative w-full mb-4 bg-slate-900 rounded-lg py-3 px-3 sm:pl-6 outline-none border-transparent autofill:bg-slate-800 appearance-none hover:appearance-none disabled:text-slate-100 text-sm transition-all duration-200"
        name="Email"
        type="text"
        placeholder="Email"
        autocomplete="off"
        @keyup.enter="onSubmit"
      >
      <p v-if="error" class="text-red mb-4">
        {{ error }}
      </p>
      <div class="w-full text-lg animate-slide-down-fade-in04s">
        <ButtonButton
          :disabled="!canSubmit"
          :has-disabled-state="false"
          :is-loading="loading"
          class="w-full mb-2"
          theme="primary"
          size="sm"
          @click.prevent.stop="onSubmit"
        >
          Sign up or Log in
        </ButtonButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'pinia';
import { useAuthStore } from '@/store/auth';

export default {
  emits: [
    'onEmailSubmit',
  ],
  data() {
    return {
      email: '',
      loading: false,
      error: null,
    };
  },
  computed: {
    canSubmit() {
      return this.email.length > 0 && /^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/i.test(this.email);
    },
  },
  methods: {
    ...mapActions(useAuthStore, ['loginEmailOneTimeLink',]),

    async onSubmit() {
      this.error = null;

      try {
        this.loading = true;
        await this.loginEmailOneTimeLink(this.email?.toLowerCase()?.trim());
        this.$emit('onEmailSubmit', this.email);
      } catch (err) {
        if (err.name === 'NotAuthorizedException') {
          this.error = 'Invalid email address.';
          this.email = '';
          return;
        }

        this.error = err.message;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
